<template>
  <div class="nav-tab">
    <li @click="setActiveParent(id)" class="nav-item">
      <div class="nav-link" :class="{ chosen: currentID === id }">
        {{ name }}
      </div>
      <div v-if="isAdmin" class="top-right" @click.stop="redirectToEdit">
        <Icon iconName="cog" size="4px" color="grey" />
      </div>
    </li>
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
export default {
  name: 'Tabs',
  components: { Icon },
  props: {
    name: String,
    id: String,
    setActiveParent: Function,
    currentID: String,
    isAdmin: Boolean
  },
  methods: {
    redirectToEdit () {
      this.$router.push({
        name: 'EditContainer',
        params: { id: this.id }
      })
    }
  }
}
</script>
<style lang="scss">
.nav-item {
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  border-color: #dee2e6 #dee2e6 #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-right: 4px;
  box-shadow: -1px -1px 4px 1px rgba(0, 0, 0, 0.05);
}
.nav-tab:hover {
  background-color: #e9f4ff;
}
.chosen {
  background: #e9f4ff;
  cursor: default;
}
.nav-tab {
  .top-right {
    top: 0;
    right: 1%;
  }
  .nav-link {
    padding: 0.5rem 1.4rem;
  }
}
</style>

<template>
    <div>
      <template v-if="!loading">
        <ContainersOverview v-if="getContainers && (myCustomers.length > 0 || myInfo.roles.some(item => item.includes('CUSTOMER')))" />
        <h2 v-else-if="!getContainers && myCustomers.length === 0 && !isFulfillerAdmin">
          No Container Structure Created yet by Admin
        </h2>
        <div v-if="myCustomers.length === 0 && isFulfillerAdmin">
          <v-sheet
            width="95%"
            height="95%"
            :elevation="1"
            :tile="false"
            style="margin: auto; padding: 10px;"
          >
            <h2 class="text-center mb-4 mt-4 " >
              <span > New Customer</span>
            </h2>
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; flex-wrap: wrap; width: 100%;">
              <v-text-field
                v-model="formField"
                solo
                :label="'Customer Name'"
                clearable
                style=" margin: auto 8px auto auto;max-width: 43%;"
              ></v-text-field>
              <v-text-field
                v-model="domainNames"
                solo
                label="Domain Name"
                clearable
                style="margin: auto auto auto 8px;max-width: 43%; "
              ></v-text-field>
            </div>
            <v-btn
              elevation="2"
              @click="submitForm()"
              :loading="saving"
              color="primary"
              style="margin: auto 10px"
            >
              Create
            </v-btn>
          </v-sheet>
        </div>
      </template>
      <template v-else>
        <v-progress-circular
          :size="70"
          :width="7"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </template>
    </div>
</template>

<script>
import ContainersOverview from '@/components/containers/ContainersOverview'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ContainersOverview
  },
  data () {
    return {
      loading: true,
      highestRole: [],
      formField: '',
      domainNames: '',
      saving: false
    }
  },
  computed: {
    ...mapGetters({
      getContainers: 'containers/getContainers',
      myCustomers: 'login/myCustomers',
      myInfo: 'login/myInfo'
    }),
    isFulfillerAdmin () {
      return (this.highestRole[0] === 'FULFILLER' && this.highestRole[1] === 'ADMIN') || this.highestRole[0] === 'SUPERADMIN'
    }
  },
  methods: {
    ...mapActions({
      createCustomer: 'login/createCustomer'
    }),
    async submitForm  () {
      this.saving = true
      const data = {
        name: this.formField,
        domainNames: [this.domainNames]
      }
      await this.createCustomer({ customer: data })
      this.clearForm()
    }
  },
  mounted () {
    this.highestRole = this.myInfo.roles[this.myInfo.roles.length - 1].split('_')

    setTimeout(() => {
      this.loading = false
    }, 1400)
  }
}
</script>
